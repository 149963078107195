@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap');

*{
  margin:0;
  padding:0;
}

#page, #root, html, body{
  width:100%;
  background:#ffffff;

}

.wrap{
  max-width: 1280px;
  margin: 0 auto;
}

body{
  font-family: "Montserrat" !important;
  font-weight: 300 !important;
}

.section{
  position: relative;
  display: block;
  clear:both;
}

#background{
  background: url('/ani7.gif');
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index:0;
  opacity: 0.15;
  filter: grayscale(100%);
}


#content{
  position: relative;
  z-index:1;
  color: #000 !important;
}

#header{
  width:100%;
  display: block;
  height: 600px;
  margin-top:-40px;
}
#logo{
  background: url('/logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 400px;
  height: 90px;
  z-index:2;
  margin: 40px auto;
  position:relative;
  top:40px;
  display: block;
}

#tags{
  background-color: #E5F230;
  padding: 40px 0;
  text-align: center;
  box-shadow: 0 10px 10px rgba(0,0,0,0.1);
}

.tag{
  display: inline-block;
  padding: 10px;
  text-transform: uppercase;
  font-size: 14px;
}

#title{
  color: #000;
  text-align: center;
  font-size: 36px !important;
  font-weight: bold;
  min-width: 900px !important;
}

.parent {
  position: relative;
  height: 100%;
}
.child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


div[role="group"]{
  margin-bottom: 15px;
}

input, textarea, .input {
    background: #ffffff21 !important;
    border: 1px solid #fff3 !important;
    backdrop-filter: blur(40px);
    color: white !important;
}

input:hover, textarea:hover, .input:hover{
  background: rgba(255,255,255,0.2) !important;
}


.form{
  display: block;
}

#form1 .form-in{

}


.form-description{
  text-align: center;
  font-size: 16px;
  margin: 10px 0 40px 0;
}

.form-fields div[role="group"]{
  max-width: 400px;
  margin: 10px auto;
}

#message, #name, #email{
  font-weight: normal;
  width: 100% !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

#name{
  background-image: url(/form/input1.png) !important;
  padding-left: 15px;
  border-radius: 0;
  margin-left: 40px;
  height: 46px;
  color: black !important;
  margin-top: -5px;
}

#email{
  background-image: url(/form/input2.png) !important;
  padding-left: 15px;
  border-radius: 0;
  margin-left: 40px;
  height: 41px;
  color: black !important;
}

#message{
  background-image: url(/form/input4.png)!important;
  background-position: 0 0!important;
  padding-left: 15px!important;
  min-height: 131px;
  border-radius: 0;
  margin-left: 40px;
  color: #000!important;
  padding: 10px;
  resize: none;
}


::placeholder{
  color: white !important;
  font-weight: bold !important;
  opacity: 1
}

::-moz-placeholder{
  opacity: 1
}

#buttonholder{
  position:relative;
  z-index: 10;
  width: 155px !important;
  margin: 0 auto !important;
  height: 68px;
}

#buttonholder button{
  margin-left: 24px !important;
  margin-top: 25px !important;
}

button[type="submit"]{
  color: white !important;
  background-color: #000 !important;
  border-radius: 0 !important;
  width: 155px !important;
  font-size: 15px;
  height: 33px;
  display: block;
  margin: 0 auto !important;
  position: relative;
  z-index: 1;
}

#paperplane{
  content: '';
    background-image: url(/form/paperplane.png) !important;
    width: 582px;
    height: 136px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: top left !important;
    position: absolute;
    z-index: 0;
    margin-left: 0;
    right: 100%;
    right: -245px;
    top: -28px;
}

#clients{
  background-color: #E5F230;
  padding: 40px 0;
  margin: 40px 0 0 0;
  text-align: center;
  box-shadow: 0 10px 10px rgba(0,0,0,0.1), 0 -10px 10px rgba(0,0,0,0.1);
}
#clients h2{
  font-weight: bold;
  font-size: 28px;
  margin: 0 20px 20px 20px;
}
.client-list{

}

.client{
  display: inline-block;
  vertical-align: center;
  max-width: 180px;
  max-height: 80px;
  margin: 10px 20px;
}

.client img{
  max-width: 180px;
  max-height: 80px;
  display: inline-block;
}

#footer{
  padding-top: 40px;
  padding-bottom: 40px;
}

#copyright{
  text-align: center;
}

.form-title{
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
}

.input-mask{
  overflow: hidden;
  height: 40px;
  position: relative;
  width: 100%;
}

.input-file{
  opacity:0;
  box-shadow: inset 0 0 0 1px red;
  width: 100%;
  height: 100%;
  font-size: 550px;
  position: absolute;
  z-index: 1;
  cursor:pointer !important;
}

.input-imitator{
  width: 100%;
  height: 40px;
  position: relative;
  z-index:0;
}

.input-imitator-label{
  padding-left:40px;
  padding-top:7px;
  color: rgba(255,255,255,0.4) !important;
}

.mobile{
  display:none;
}


.textloop{
  display: inline-block;
}

.form-fields{
  margin-left:-40px;
  padding: 0 20px;
}

@media only screen and (max-width: 600px) {
  .mobile{
    display:block !important;
  }
  .textloop{
    display: inline-block;
  }
  .wrap{
    width: 100%;
  }
  #logo{
    width: 200px;
  }
  #header{
    height: 500px;
  }
  #title{
    padding:0 20px;
    margin: 30px 0 20px 0;
    font-size: 32px !important;
    min-width: 0 !important;
  }
  #title span{
    display: block; 
    clear:both;
  }
  #content{
    width: 100%;
  }
  #clients h2{
    font-size: 22px;
  }

  .client, .client img{
    max-width: 120px;
    max-height: 80px;
  }

  #rainbow-head{
    display:none;
  }

  #forms .form{
    display:block;
    clear:both;
    width: 100%;
  }
  #form1 .form-in,
  #form2 .form-in{
    padding: 0 20px;
  }
  .rainbow-head{
    padding: 20px 0 !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .chakra-modal__content-container{
    padding: 0 20px;
  }

  .chakra-modal__content-container .chakra-button{
    width: 100%;
  }

  .mobile .rainbow-head-title{
    text-align:center;
    width: 100%;
    font-size: 22px !important;
  }

  #paperplane{
    left: 16%;
    top: 14px;
    width: 400px !important;
    right: auto !important;
    margin-right: 0 !important;
    margin-left: -200px !important;
  }
  .client{
    margin-bottom: 20px;
  }

  .tag{
    font-size: 12px;
  }

  .form-fields{
    margin-left:-40px;
    padding: 0 20px;
  }
}

@media only screen and (max-width: 456px) {
  
  /*
#message, #name, #email{
  width: 260px;
}
#message{
  min-height: 95px;
}
*/
}